import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const showRequestError = {
  install: (Vue) => {
    Vue.prototype.$showRequestError = async (error) => {
      let errorText = Vue.prototype.$t("ERRORS.SOMETHING_WENT_WRONG");
      
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.errors) {
            for (const err of error.response.data.errors) {
              errorText = err.detail ? err.detail : err.title;
            }
          }
        }
      }

      await swal.fire({
        title: Vue.prototype.$t(`ERRORS.${errorText}`)
          ? Vue.prototype.$t(`ERRORS.${errorText}`)
          : errorText,
        icon: "error",
        confirmButtonText: Vue.prototype.$t("COMMON.OK"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
    };
  },
};

export default showRequestError;
