<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <img src="../../../assets/img/AdministrationIcon.svg" alt="" />
        <h1>{{ $t("APPS.ADMIN_MODULE") }}</h1>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
