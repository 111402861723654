<template>
  <div>
    <notifications></notifications>
    <base-nav
      v-model="showMenu"
      type="light"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      expand="lg"
    >
      <div slot="brand" class="navbar-wrapper">
        <router-link class="navbar-brand" :to="{ name: 'Dashboard' }" custom>
          <img :src="appConfig.config_manager_app_logo" />
        </router-link>
      </div>

      <template>
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link :to="'/'" custom>
                <img :src="appConfig.config_manager_app_logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                class="navbar-toggler"
                @click="showMenu = false"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav mr-auto">
          <!--<li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-link" custom>
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>-->
        </ul>
        <hr class="d-lg-none" />
        <ul class="navbar-nav align-items-lg-center ml-lg-auto">
          <lang-switcher />
          <!--<li class="nav-item d-none d-lg-block ml-lg-4">
            <a
              href="https://www.creative-tim.com/product/vue-argon-dashboard-pro-laravel"
              target="_blank"
              class="btn btn-neutral btn-icon"
            >
              <span class="btn-inner--icon">
                <i class="fas fa-shopping-cart mr-2"></i>
              </span>
              <span class="nav-link-inner--text">Purchase now</span>
            </a>
          </li>-->
        </ul>
      </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <!-- <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <ul class="nav nav-footer"></ul>
          </div>
          <div class="col-xl-6 pr-1">
            <div class="copyright text-center text-lg-right text-muted"></div>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ZoomCenterTransition } from "vue2-transitions";
import { BaseNav } from "@/components";
import { LangSwitcher } from "@/components";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
    LangSwitcher,
  },

  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },

  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },

  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },

  beforeDestroy() {
    this.removeBackgroundColor();
  },

  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
