import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/organization-group`, options);
  if(response) console.log(response,"response===========");
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .get(`${url}/organization-group/${id}`, options);
  let organizationgroups = jsona.deserialize(response.data);
  console.log(organizationgroups,"org in get/id ",id);
  delete organizationgroups.links;
  return organizationgroups;
}

async function getWithOrg(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  const response = await axios
    .get(`${url}/group-organization/${id}`, options);
    console.log(response,"resp in get by org")
 /*  let organizationgroups = jsona.deserialize(response.data); */
/*   console.log(organizationgroups,"org in get id/org ",id); */
  /* delete organizationgroups.links;
  return organizationgroups; */
  return response.data;
}

async function add(organizationgroups) {
  const payload = jsona.serialize({
    stuff: organizationgroups,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .post(`${url}/organization-group`, payload, options);
  return jsona.deserialize(response.data);
}

async function update(organizationgroups) {
  const payload = jsona.serialize({
    stuff: organizationgroups,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .patch(
      `${url}/organization-group/${organizationgroups.id}`,
      payload,
      options
    );
  return jsona.deserialize(response.data);
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/organization-group/${id}`, options);
}

async function attach(data) {
  try {
    const payload = {
      "organization_id": data.organizationId,
      "organization_group_id": data.organizationGroupId,
    };
    console.log(payload,"payload")
    const response = await axios.post(`${url}/attach-organization`, payload);
    return response.data; // Optionally return data if needed
  } catch (error) {
    console.error('Error attaching organization group:', error);
    throw error; // Optional: Throw error to handle in the caller function
  }
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  attach, 
  getWithOrg
};
