export default {
    EVENTS_LIST: "Liste des évenements",
    ADD_EVENT: "Ajouter un évenement",
    EVENTS_ADDED: "Evenement ajoutée",
    OWNER: "Propriétaire",
    EDIT_EVENTS: "Modifier un évenement",
    EVENTS_UPDATED: "Evenement mis à Jour",
    DELETE_THIS_EVENT: "Supprimer cette évenement ?",
    EVENT_DELETED: "Evenement Supprimée",
    EVENT_ACCEPTED: "Evenement Acceptée",
    VIEW_EVENT: "Evenement",
    DATE_INVALIDE: "Date de fin doit etre supérieur à la date de début",
    TITLE: 'Titre',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    RECURENT: 'Récurrent',
    OCURENCE: 'Définir la récurrence',
    FREQUENCE: 'Répétez tous les',
    SELECT: 'Selectionnez',
    ALL_DAYS: 'Tous les jours',
    ALL_WEEKS: 'Tous les semaines',
    ALL_MONTH: 'Tous les mois',
    THIS_EVENTS: 'Cet événement',
    ALL_EVENTS: 'Toute la série',
    EDIT: 'Modifier',
    DELETE_THIS_EVENT: 'Supprimer cet événement',
    DELETE_ALL_EVENT: 'Supprimer toute la série',
    ADD: 'Ajouter',
    COLOR: 'Couleur',
    NOT_SELECTED: 'Oops... selectionnez une date valide',
    SELECT_AUCTION: 'selectionnez un encan'
}