export default {
    CHARGES: "Charges",
    CHARGES_LIST: "Liste des charges",
    ADD_CHARGE: "Ajouter une charge",
    CHARGE_ADDED: "Charge ajoutée",
    EDIT_CHARGE: "Modifier une charge",
    CHARGE_UPDATED: "Charge mise à jour",
    DELETE_THIS_CHARGE: "Supprimer cette charge ?",
    CHARGE_DELETED: "Charge supprimée",
    TYPE: "Type",
    CATEGORY: "Catégorie",
    VIEW_CHARGE: "Charge",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_CHARGES:
      'Une ou plusieures charges ont déjà été enregistrées avec le nom {name}. S\'agit-il de la même charge ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créée le',
    fee: 'Frais',
    commission: 'Commission',
    COMMISSION: 'Commission',
    ADD_COMMISSION: 'Ajouter une commission',
    palier: 'Palier',
    pourcentage: 'Pourcentage',
    montant_fixe: 'Montant Fixe',
    KEY: 'Clé Unique',
    AMOUNT: 'Montant',
    PERCENTAGE: 'Pourcentage',
    PALIERS: 'Paliers',
    ADD_PALIER: 'Ajouter un palier',
    MIN_VALUE: 'Montant Minimal',
    MAX_VALUE: 'Montant Maximal',
    min_value_required: 'Le montant minimal est requis',
    max_value_required: 'Le montant maximal est requis',
    ACTIONS: 'Actions',
    RANGE: 'Portée',
    COUT: 'Coût',
    MIN: 'Min',
    MAX: 'Max',
    CATEGORY_REQUIRED:'Veuillez sélectionner la catégorie',
    TYPE_REQUIRED:'Veuillez sélectionner le type',
    NAME_REQUIRED:'Veuillez entrer le nom',
    AMOUNT_REQUIRED:'Veuillez entrer le montant',
    PERCENTAGE_REQUIRED: 'Veuillez préciser le pourcentage',
    RANGE_REQUIRED:'Veuillez préciser une portée',
    COUT_REQUIRED:'Veuillez préciser un coût',
    IN_PERCENTAGE: 'En pourcentage',
    CLEAR_FORM:'Les données déjà entrées seront perdues',
    CONFIRM_CLEAR_FORM_TITLE: 'Réinitialisation du formulaire',
    CLEAR_FORM_CONFIRM_BUTTON: 'Effacer',
    CANCEL: 'Annuler',
    SELECT:'Sélectionner une charge',
    SELECT_CHARGES:'Ajouter des charges',
    REQUIRED: 'Veuillez sélectionner les charges à ajouter',
    MIN_LESS_THAN_MAX: 'La valeur minimale doit-être inférieure à la valeur maximale',
  };
  