import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/invitations`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .get(`${url}/invitations/${id}`, options);
  let invitation = jsona.deserialize(response.data);
  delete invitation.links;
  return invitation;
}

async function add(invitation) {
  const payload = jsona.serialize({
    stuff: invitation,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .post(`${url}/invitations`, payload, options);
  return jsona.deserialize(response.data);
}

async function update(invitation) {
  const payload = jsona.serialize({
    stuff: invitation,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
    .patch(
      `${url}/invitations/${invitation.id}`,
      payload,
      options
    );
  return jsona.deserialize(response.data);
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/invitations/${id}`, options);
}
function accept(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return  axios.get(`${url}/invitations/${id}/accept`, options);
}
function approve(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/invitations/${id}/approve`, options);
}

// function generateSubscriptionInvoice(id, data) {
//   const options = {
//     headers: {
//       Accept: "application/vnd.api+json",
//       "Content-Type": "application/vnd.api+json",
//     },
//   };

//   return axios
//     .post(
//       `${url}/organizations/${id}/generate_subscription_invoice`,
//       data,
//       options
//     )
//     .then((response) => {
//       return jsona.deserialize(response.data);
//     });
// }

export default {
  list,
  get,
  add,
  update,
  destroy,
  accept,
  approve
  // generateSubscriptionInvoice,
};
