import store from "../store";
import moment from "moment";

const timeZoneDateFormat = {
  install: (Vue) => {
    Vue.prototype.$timeZoneDateFormat = (value, format = "LLL") => {
      const me = store.getters["profile/me"];
      const timeZone = moment().utcOffset();
      const dateTimeZone = moment().utcOffset(value).utcOffset;
      const momentDate = moment(value).utcOffset(timeZone + dateTimeZone);
      return momentDate.locale(me?.locale ?? "fr").format(format);
    };
  },
};

export default timeZoneDateFormat;
