import service from "@/store/services/invitations-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  invitation: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, invitation) => {
    state.invitation = invitation;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((invitation) => {
      commit("SET_RESOURCE", invitation);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((invitation) => {
      commit("SET_RESOURCE", invitation);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((invitation) => {
      commit("SET_RESOURCE", invitation);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
  accept({ commit, dispatch }, params) {
    return service.accept(params, this.$axios);
  },
  approve({ commit, dispatch }, params) {
    return service.approve(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  invitation: (state) => state.invitation,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((invitation) => ({
      id: invitation.id,
      name: `${invitation.name}`,
    }));
  },
};

const invitations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default invitations;
