import service from "@/store/services/organizations-groups-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  organizationGroup: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, organizationGroup) => {
    state.organizationGroup = organizationGroup;
  },
/*   SET_META: (state, meta) => {
    state.meta = meta;
  }, */
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list}) => {
      console.log(list,"list");
      commit("SET_LIST", list);
    /*   commit("SET_META", meta); */
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((organizationGroup) => {
      console.log(organizationGroup,"===================");
      commit("SET_RESOURCE", organizationGroup);
    });
  },

  getWithOrg({ commit, dispatch }, params) {
    return service.getWithOrg(params, this.$axios).then((organizationGroup) => {
      console.log(organizationGroup,"===================getbyOrg");
      commit("SET_RESOURCE", organizationGroup);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((organizationGroup) => {
      commit("SET_RESOURCE", organizationGroup);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((organizationGroup) => {
      commit("SET_RESOURCE", organizationGroup);
    });
  },

  attach({ commit, dispatch }, params) {
    return service.attach(params, this.$axios).then((organizationGroup) => {
      console.log(organizationGroup,"test attach")
      /* commit("SET_ATTACHED_RESULT", organizationGroup); */
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

/*   generateSubscriptionInvoice({ commit, dispatch }, { organizationGroupId, data }) {
    return service.generateSubscriptionInvoice(
      organizationGroupId,
      data,
      this.$axios
    );
  }, */
};

const getters = {
  list: (state) => state.list,
/*   listTotal: (state) => state.meta.page.total, */
  organizationGroup: (state) => state.organizationGroup,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((organizationGroup) => ({
      id: organizationGroup.id,
      name: `${organizationGroup.name}`,
    }));
  },
};

const organizationGroups = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default organizationGroups;
