export default {
  LOCATIONS_LIST: "Liste des concessions",
  ADD_LOCATION: "Ajouter un concession",
  LOCATION_ADDED: "Concession ajouté",
  LOCATIONS_UPDATED: "Concession modifié",
  EDIT_LOCATION: "Modifier cet concession",
  DELETE_THIS_LOCATION: "Supprimer cet concession ?",
  LOCATION_DELETED: "Concession supprimé",
  VIEW_LOCATION: "Concession",
  IS_MUNICIPAL: "Est une municipalité",
};
