export default {
    LABELS: "Étiquettes",
    LABELS_LIST: "Liste des étiquettes",
    ADD_LABEL: "Ajouter une étiquette",
    LABEL_ADDED: "Étiquette ajoutée",
    EDIT_LABEL: "Modifier une étiquette",
    LABEL_UPDATED: "Étiquette mise à jour",
    DELETE_THIS_LABEL: "Supprimer cette étiquette ?",
    LABEL_DELETED: "Étiquette supprimée",
    LABEL_TYPE: "Catégorie d'étiquette",
    CATEGORY: "Catégorie",
    VIEW_LABEL: "Étiquette",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_LABELS:
      'Une ou plusieures étiquettes ont déjà été enregistrées avec le nom {name}. S\'agit-il de la même étiquette ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créée le',
    damage: 'Dommage',
    option: 'Option',
    declaration: 'Déclaration',
    KEY: 'Clé Unique'
  };
  