export default {
  PER_PAGE: "Par page",
  STATUS: "Statut",
  SEARCH: "Recherche",
  NUMERO: "N°",
  TYPE: "Type",
  TRANSACTION_LABEL: "Libellé",
  TRANSACTION_AUTHOR: "Autheur",
  TRANSACTION_AMOUNT: "Montant",
  TRANSACTION_STATUS: "Statut",
  TRANSACTION_CREATED_AT: "Date",
  TRANSACTION_TYPE: ".",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  draft: "Brouillon",
  awaiting_signature: "En attente de signature",
  signature_declined: "Signature refusée",
  awaiting_validation: "En attente de validation",
  admin_refused: "Invalidée par l'administrateur",
  pending: "En attente",
  bank_refused: "Refusée par la banque",
  completed: "Complété",
  TOTAL_BALANCE: "Balance Totale",
  TRANSACTIONS_LIST: "Liste des transactions",
  DEPOSIT: "Dépot de fond",
  WITHDRAW: "Retrait",
  DEPOSIT_NOTE: "Note",
  DEPOSIT_LABEL: "Libellé",
  EDIT_TRANSACTION: "Modifier",
  CONTINUE_TRANSACTION: "Continuer",
  DEPOSIT_SUCCESS: "Dépôt effectué avec succès !",
  DEPOSIT_FAILED: "Une erreur s'est produite lors du dépôt",
  ERROR: "Erreur",
  SUCCESS: "Succès",
  TRANSACTION_AMOUNT_REQUIRED: "Veuillez saisir le montant",
  INVALID_FORM: "Formulaire invalide",
  TRANSACTION_AMOUNT_MINIMUM: "Un minimum de {minimum_amount} est exigé",
  WALLET: "Portfeuille",
  PAYMENT_METHODS: "Moyens de paiement",
  WALLET_CREATED: "Portefeuille crée avec succès",
  WALLET_CREATION_FAILED:
    "Une erreur s'est prodduite lors de la création du portefeuille",
  NO_WALLET_MESSAGE: "Désolé, aucun portefeuille trouvé, merci d'en créer",
  CREATE_WALLET: "Créer un porfeuille",
  PAYMENTMETHODS_LIST: "Liste des moyens de paiement",
  SELECT_PAYMENTMETHOD: "Sélectionner un moyen de paiement",
  HOLDER: "Propriétaire",
  DETAILS: "Détails",
  SIGNERS: "Signataires",
  account_number: 'Numéro de compte',
  bank_code: 'Code banque',
  iban: 'RIB',
  firstname: 'Prénom',
  lastname: 'Nom',
  address: 'Adresse',
  phone: 'Téléphone',
  email: 'E-mail',
  bank_account: 'Compte bancaire',
  ADD_PAYMENTMOTHOD: 'Ajouter un moyen de paiement',
  ADD_THE_PAYMENTMOTHOD: 'Ajouter le moyen de paiement',
  EDIT_PAYMENTMETHOD: 'Modifier le moyen de paiement',
  INSUFFICIENT_FUNDS: 'Fonds insuffisants',
  account_number_required: 'Le numéro du compte est requis',
  bank_code_required: 'Le code banque est requis',
  iban_required:"Le RIB est requis",
  PAYMENT_METHOD_CREATED: 'Moyen de paiement ajouté avec succès',
  PAYMENT_METHOD_UPDATED: 'Moyen de paiement mis à jour avec succès',
  PAYMENT_METHOD_CREATION_FAILED: "Une erreur s'est produite lors de la création du moyen de paiement",
  PAYMENT_METHOD_UPDATE_FAILED: "Une erreur s'est produite lors de la mise à jour du moyen de paiement",
  PAYMENT_METHOD_HOLDER: 'Titulaire',
  email_correct: 'Veuillez entrer une adresse e-mail valide',
  email_required: "Veuillez entrer l'adresse e-mail",
  PAYMENT_METHOD_ACCOUNT: 'Compte',
  PAYMENT_METHOD_SIGNERS: 'Signataires',
  lastname_required:'Veuillez entrer le nom',
  firstname_required:'Veuillez entrer le prénom',
  ADD_SIGNER: 'Ajouter un signataire',
  actions: 'Actions',
  EDIT: 'Modifier',
  DELETE: 'Supprimer',
  CONFIRM_DELETE_TITLE: 'Confirmez la suppression',
  CONFIRM_DELETE_MESSAGE: 'Êtes-vous sûr de vouloir supprimer ?',
  DELETE_CONFIRM_BUTTON: 'Oui supprimer',
  DELETE_CANCEL_BUTTON: 'Annuler',
  PAYMENT_METHOD_DELETED: 'Moyen de paiement supprimé !',
  PAYMENT_METHOD_DELETE_FAILED: "Désolé une erreur s'est produite lors de la suppression !",
  FIELD: 'Champ',
  VALUE: 'Valeur',
  TRANSACTION_HOLDER: 'Propriétaire',
  TRANSACTION:'Transaction',
  DEPOSIT_DEMAND:'Demande de dépôt',
  WITHDRAW_DEMAND:'Demande de retrait',
  code:'Code',
  label:'Libellé',
  status:'Statut',
  created_at:'Créer le ',
  CURRENT_WALLET_BALANCE:'Solde actuel',
  WALLET_HOLDER: "Propriétaire du portefeuille",
  PAYMENTMETHOD: 'Moyen de paiement',
  APPROVAL: 'Approbation',
  VALIDATE: 'Approuver',
  PUT_IN_PENDING: 'Mettre en attente',
  SAVE: 'Sauvegarder',
  DECLINE: 'Refuser',
  TRANSACTION_STATUS_UPDATED:'Le statut de la transaction a bien été mis à jour !',
  COMMENT: 'Commentaire',
  CONFIRM_TRANSACTION_STATUS_UPDATE_MESSAGE: 'Confirmez la mise à jour du statut de la transaction',
  CONFIRM_TRANSACTION_STATUS_UPDATE_TITLE: 'Mise à jour du statut',
  UPDATE_CONFIRM_BUTTON: 'Mettre à jour',
  UPDATE_CANCEL_BUTTON: 'Annuler',
  TERMINATE_TRANSACTION: 'Terminer la transaction',
  BANK_REFUSED: "C'est un refus de la banque",
  SELECT: 'Sélectionner',
  SELECT_PAYMENT_METHOD: 'Veuillez sélectionner un moyen de paiement',
  DEMANDE_SUCCESS: 'Demande enregistrée avec succès !'
};
